import { OktaAuth } from '@okta/okta-auth-js'

export const OKTA_CALLBACK_PATH = '/login/callback'
const OKTA_ISSUER = `${process.env.REACT_APP_OKTA_ISSUER}/oauth2/default`
const OKTA_REDIRECT_URI = `${window.location.origin}${OKTA_CALLBACK_PATH}`
const OKTA_CLIENT_ID = `${process.env.REACT_APP_OKTA_CLIENT_ID}`

export const oktaAuth = new OktaAuth({
  clientId: OKTA_CLIENT_ID,
  issuer: OKTA_ISSUER,
  redirectUri: OKTA_REDIRECT_URI,
  scopes: ['openid', 'email', 'profile'],
  pkce: true,
})
