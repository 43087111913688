import styled from 'styled-components'
import Button from '@splunk/react-ui/Button'
import Dropdown from '@splunk/react-ui/Dropdown'
import Menu from '@splunk/react-ui/Menu'

export const AppHeader = styled.header`
  color: black;
  padding-top: 20px;
`
export const DropdownContent = styled(Menu)``

export const StyledDropdownOption = styled(Menu.Item)`
  a {
    text-decoration: none;
    color: black;
  }
`

export const StyledDropdown = styled(Dropdown)`
  width: 10% !important;
  :hover ${DropdownContent} {
    display: block;
  }
`

export const DropdownButton = styled(Button)`
  float: right !important;
  font-weight: bold;
  font-size: 16px;
  border: none;
  color: black !important;
  outline: none;
  background-color: transparent !important;
  :hover {
    background-color: rgb(155, 149, 149) !important;
  }
`
