import { oktaAuth } from 'config/oktaConfig'
import { AuthTokenError, ResponseError } from 'utils/custom-errors'

const API_ORIGIN = process.env.REACT_APP_API_URL

/**
 * Does error handling for fetch response if response isn't a 200 and returns JSON response
 * @param response The response from fetch
 */
const handleResponse = async (response: Response) => {
  const contentType = response.headers.get('content-type')
  if (!contentType || !contentType.includes('application/json')) {
    throw new TypeError("Oops, we haven't got JSON!")
  }
  if (!response.ok) {
    const error = new ResponseError('Response Error.')
    error.details = JSON.stringify(await response.json())
    error.status = response.status
    throw error
  }
  return response.json()
}

/**
 * The base method defined for calling REST endpoints of the configured API_ORIGIN.
 * @param url The URL route to which the request is sent
 * @param settings An optional set of parameters used in initializing the request
 */
export const fetchApi = async (url: string, settings: RequestInit = {}) => {
  const token = oktaAuth.getAccessToken()
  if (!token) {
    // Redirect the user to the Okta login page
    oktaAuth.signInWithRedirect()
    return Promise.reject(new AuthTokenError())
  }
  const headers = {
    ...settings.headers,
    Authorization: `Bearer ${token}`,
  }

  const response = await fetch(`${API_ORIGIN}${url}`, {
    ...settings,
    headers,
  })
  return handleResponse(response)
}

/**
 * Performs a GET request to the specified URL
 * @param url
 */
export const getFetch = (url: string) =>
  fetchApi(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })

/**
 * Performs a POST request to the specified URL with the provided body.
 * @param url
 * @param body The body contents to be sent with the POST request
 */
export const postFetch = (url: string, body = {}) =>
  fetchApi(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })

/**
 * Performs a PUT request to the specified URL with the provided body.
 * @param url
 * @param body The body contents to be sent with the PUT request
 */
export const putFetch = (url: string, body = {}) =>
  fetchApi(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })

/**
 * Performs a DELETE request to the specified URL.
 * @param url
 */
export const deleteFetch = (url: string) =>
  fetchApi(url, {
    method: 'DELETE',
  })
