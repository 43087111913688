import React, { useState, useMemo } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import {
  LoginCallback,
  Security,
  SecureRoute,
  useOktaAuth,
} from '@okta/okta-react'
import { ErrorBoundary } from 'react-error-boundary'
import Button from '@splunk/react-ui/Button'
import useSWR from 'swr'
import { CustomErrorType } from 'utils/custom-errors'
import WaitSpinner from '@splunk/react-ui/WaitSpinner'
import { getFetch } from 'utils/fetch'
import { oktaAuth, OKTA_CALLBACK_PATH } from 'config/oktaConfig'
import { swrOptions } from 'config/swrConfig'
import { TaskPage } from 'components/TaskPage'
import { Sidebar } from 'components/Sidebar'
import { Header } from 'components/Header'
import { Logout } from 'components/Logout'
import { ClientError } from 'components/ClientError/client-error'
import logo from '../splunk-on-call-logo.svg'
import {
  AppContainer,
  AppContent,
  AppHome,
  AppLogo,
  AppMain,
  StyledErrorBoundary,
} from './app-styles'

const Splash = () => (
  <AppHome>
    <AppLogo src={logo} alt='Splunk On-Call Logo' />
  </AppHome>
)

interface FallbackProps {
  error: CustomErrorType
  resetErrorBoundary: (...args: Array<unknown>) => void
}

const transformRoute = ({
  tag,
  summary,
  method,
  path,
  description,
}: RouteType) => {
  const slugify = (text: string) =>
    text
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '')
  return {
    clientRoute: `/${slugify(tag)}/${slugify(summary)}`,
    method: method.toLowerCase(),
    apiPath: path,
    tag,
    summary,
    description,
  }
}

export const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => (
  <StyledErrorBoundary role='alert'>
    <h1>Error. Something went wrong.</h1>
    <pre>Error Message: {error?.message}</pre>
    {error.details?.length && <pre>Error Details: {error?.details}</pre>}
    <Button
      label='Try Again'
      onClick={resetErrorBoundary}
      appearance='destructive'
    />
  </StyledErrorBoundary>
)

const SecuredApp = () => {
  const { authState } = useOktaAuth()
  const [appRoutes, setRoutes] = useState<TransformedRouteType[]>([])

  const { data: { routes } = {}, error } = useSWR<
    RoutesApiType,
    CustomErrorType
  >(
    () => (authState.isPending ? null : '/service/info/routes'),
    getFetch,
    swrOptions('/service/info/routes')
  )

  useMemo(() => {
    const transformedRoutes =
      routes === undefined ? [] : routes.map(transformRoute)
    setRoutes(transformedRoutes)
  }, [routes])

  if (error) {
    /* eslint no-console: ["error", { allow: ["error"] }] */
    console.error(error)
    return <WaitSpinner title='loading' />
  }

  return (
    <AppContainer>
      <Sidebar routes={appRoutes} />
      <AppContent>
        <Header />
        <AppMain>
          <Switch>
            <Route path={OKTA_CALLBACK_PATH} component={LoginCallback} />
            <Route exact path='/logout' component={Logout} />
            <SecureRoute exact path='/'>
              <Splash />
            </SecureRoute>
            {appRoutes.map(({ summary, tag, apiPath, method, clientRoute }) => (
              <SecureRoute key={clientRoute} path={clientRoute} exact>
                <TaskPage
                  summary={summary}
                  tag={tag}
                  apiPath={apiPath}
                  method={method}
                />
              </SecureRoute>
            ))}
            <Route exact path='*' component={ClientError} />
            <Redirect to='/404' />
          </Switch>
        </AppMain>
      </AppContent>
    </AppContainer>
  )
}

export const App = () => (
  <BrowserRouter>
    <Security oktaAuth={oktaAuth}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <SecuredApp />
      </ErrorBoundary>
    </Security>
  </BrowserRouter>
)
