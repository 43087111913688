import { cache } from 'swr'

export const swrOptions = (key: string) => ({
  revalidateOnFocus: false,
  revalidateOnMount: !cache.has(key),
  revalidateOnReconnect: false,
  refreshWhenOffline: false,
  refreshWhenHidden: false,
  refreshInterval: 0,
  dedupingInterval: 0,
})
