import * as z from 'zod'

interface ZodMappingWithIndexSignature {
  [key: string]:
    | z.ZodString
    | z.ZodBoolean
    | z.ZodNumber
    | z.ZodArray<z.ZodString>
}
interface MappingWithIndexSignature {
  [key: string]: {
    defaultValue:
      | string
      | Array<string>
      | boolean
      | number
      | FiniteDurationObject
    type: string
  }
}

export interface FiniteDurationObject {
  length: number
  unit: string
}

interface Dictionary<T> {
  [key: string]: T
}
export interface FormInputObjectType {
  inputId: string
  inputDescription?: string
  inputTitle: string
  inputType: string
  paramLocation: string
  required: boolean
  defaultValue: string | Array<string> | boolean | number | FiniteDurationObject
}

/**
 * swaggerToZodMapper
 * @type {object}
 * @property {zod.ZodBoolean} boolean - ZodBoolean
 * @property {zod.ZodNumber} integer - ZodNumber
 * @property {zod.ZodString} string - ZodString
 * @property {zod.ZodObject} object - ZodObject
 */
const swaggerToZodMapper: Dictionary<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  z.ZodBoolean | z.ZodNumber | z.ZodString | z.ZodObject<any>
> = {
  boolean: z.boolean(),
  integer: z.number().int().nonnegative(),
  string: z.string().refine((val) => val.trim().length > 0, {
    message: 'Required',
  }),
  object: z.object({
    length: z.number().int().nonnegative(),
    unit: z.string(),
  }),
}

/**
 * Input object type definition
 * @typedef {Object} InputObject
 * @property {string} type - the input type attribute value
 * @property {React.ElementType} component - the component used in the form (based off the parameter type)
 */

/**
 * swaggerToInputMapper
 * @type {object}
 * @property {InputObject} array - input component to use for array types
 * @property {InputObject} boolean - input component to use for boolean types
 * @property {InputObject} integer - input component to use for integer types
 * @property {InputObject} string - input component to use for string types
 * @property {InputObject} object - input component to use for object types
 */
const swaggerToInputMapper: MappingWithIndexSignature = {
  array: { type: 'array', defaultValue: [] },
  boolean: { type: 'checkbox', defaultValue: false },
  integer: { type: 'number', defaultValue: 0 },
  string: { type: 'text', defaultValue: '' },
  object: { type: 'object', defaultValue: { length: 0, unit: 'seconds' } },
}

/**
 * @description Returns an Zod schema (i.e. string, boolean, int, etc.) with validation rules (i.e. max/min length, required, etc.) from a Swagger parameter type. This will get extended in the futute to also take a Swagger parameter format.
 * @function getZodTypeWithValidationRules
 * @param def Swagger Parameters Definitions Object
 */

const getZodTypeWithValidationRules = (
  { type, items }: { type: string; items?: { type: string } },
  required: boolean
) => {
  let zPrimitive
  if (type === 'array' && items?.type === 'string') {
    zPrimitive = z.array(swaggerToZodMapper[items.type])
  } else {
    zPrimitive = swaggerToZodMapper[type]
  }

  return required && 'nonempty' in zPrimitive
    ? zPrimitive.nonempty({ message: 'Required' })
    : zPrimitive
}

/* eslint-disable no-param-reassign */

/**
 * @description Returns an ZodObject (used for form validation) from a Swagger parameters definition. This is schema is passsed to the resolver for react-hook-forms.
 * @function getZodFromSwagger
 * @param parameters Swagger Parameters Definitions Object
 * @returns {zod.ZodObject}
 */
export const getZodFromSwagger = (
  parameters: NormalizedParameterObjectType[]
) =>
  parameters.reduce(
    (
      acc: z.ZodObject<ZodMappingWithIndexSignature>,
      { name, required, type, items }
    ) => {
      const zodType = getZodTypeWithValidationRules({ type, items }, required)
      const newObj = { [name]: zodType }
      const extendedAcc = acc.extend(newObj)
      return extendedAcc
    },
    z.object({})
  )
/* eslint-enable no-param-reassign */

/**
 * @description Returns an array of form inputs from a Swagger parameters definition. The array is used to build the form componenet.
 * @function getInputsFromSwagger
 * @param parameters Swagger Parameters Definitions Object
 * @returns {InputObject[]}
 */
export const getInputsFromSwagger = (
  parameters: NormalizedParameterObjectType[]
) =>
  parameters.map(
    ({ name, description, displayName, in: paramLocation, required, type }) => {
      const { type: inputType, defaultValue } = swaggerToInputMapper[type]
      return {
        inputId: name,
        inputTitle: displayName,
        inputDescription: description,
        inputType,
        paramLocation,
        required,
        defaultValue,
      }
    }
  )

/**
 * @description Normalize swagger parameters for parsing
 * @function normalizeSwaggerParameters
 * @param parameters Swagger Parameters Definitions Object
 */
export const normalizeSwaggerParameters = (
  parameters: SwaggerParameterObjectType[]
) =>
  parameters.flatMap(({ in: paramLocation, name, ...rest }) => {
    // https://swagger.io/docs/specification/2-0/describing-request-body/
    if (paramLocation === 'body' && rest.schema) {
      const { schema } = rest
      return Object.entries(schema.properties).map(
        ([key, { description, displayName, type, items }]) => {
          const required = schema.required.includes(key)
          return {
            in: paramLocation,
            name: key,
            description,
            displayName,
            required,
            type,
            items,
          } as NormalizedParameterObjectType
        }
      )
    }
    const { description, displayName, required, type, items } = rest
    return {
      in: paramLocation,
      name,
      description,
      displayName,
      required,
      type,
      items,
    } as NormalizedParameterObjectType
  })
