import React from 'react'
import { FieldErrors } from 'react-hook-form'
import { CustomError } from 'utils/custom-errors'
import { FiniteDurationObject } from 'utils/swagger-parser'
import {
  StyledMultiselect,
  StyledSwitch,
  StyledNumber,
  StyledText,
} from './task-form-styles'

const FiniteDurationComponent = ({
  inputId,
  value,
  onChange,
  errors: inputErrors,
}: {
  value: FiniteDurationObject
  inputId: string
  onChange: (
    string?: string | number | string[] | boolean | FiniteDurationObject
  ) => void
  errors: FieldErrors
}) => (
  <div>
    <StyledNumber
      value={value.length}
      id={inputId}
      placeholder='seconds'
      error={inputId in inputErrors}
      onChange={(e: Event, { value: eventValue }: { value: number }) =>
        onChange({ length: eventValue, unit: 'seconds' })
      }
    />
  </div>
)

export const renderInputType = ({
  inputTitle,
  inputType,
  inputId,
  value,
  onChange,
  errors: inputErrors,
}: {
  inputTitle: string
  inputType: string
  inputId: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any

  onChange: (
    string?: string | number | string[] | boolean | FiniteDurationObject
  ) => void
  errors: FieldErrors
}) => {
  if (inputType === 'array') {
    return (
      <StyledMultiselect
        inline
        allowNewValues
        error={inputId in inputErrors}
        onChange={(e: Event, { values: eventValue }: { values: string[] }) => {
          onChange(eventValue)
        }}
        values={value}
      />
    )
  }
  if (inputType === 'text') {
    return (
      <StyledText
        value={value}
        id={inputId}
        placeholder={inputTitle}
        error={inputId in inputErrors}
        onChange={(e: Event, { value: eventValue }: { value: string }) => {
          onChange(eventValue)
        }}
      />
    )
  }
  if (inputType === 'number') {
    return (
      <StyledNumber
        value={value}
        id={inputId}
        placeholder={inputTitle}
        error={inputId in inputErrors}
        onChange={(e: Event, { value: eventValue }: { value: number }) =>
          onChange(eventValue)
        }
      />
    )
  }
  if (inputType === 'checkbox') {
    return (
      <StyledSwitch
        id={inputId}
        appearance='checkbox'
        selected={value}
        onClick={(e: Event, { selected }: { selected: boolean }) =>
          onChange(!selected)
        }
      />
    )
  }
  if (inputType === 'object') {
    return (
      <FiniteDurationComponent
        value={value}
        inputId={inputId}
        onChange={onChange}
        errors={inputErrors}
      />
    )
  }
  throw new CustomError(`Input type of <${inputType}> not accounted for.`)
}

/**
 * Format the fetch response from the user's form submit to be shown in the message container
 * @param response
 */
export const formatResponse = (response: Error | unknown) => {
  if (response instanceof Error) {
    // https://stackoverflow.com/questions/18391212/is-it-not-possible-to-stringify-an-error-using-json-stringify
    const parsedErr = JSON.parse(
      JSON.stringify(response, Object.getOwnPropertyNames(response))
    )
    const { status, title, message, details, source } = parsedErr
    return {
      type: 'error',
      response: {
        status,
        title,
        message,
        source,
        ...(details !== undefined && { details: JSON.parse(details) }),
      },
    }
  }
  return {
    type: 'success',
    response,
  }
}
