import React, { useMemo, useState } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import WaitSpinner from '@splunk/react-ui/WaitSpinner'
import Message from '@splunk/react-ui/Message'
import * as z from 'zod'
import {
  FiniteDurationObject,
  getInputsFromSwagger,
  getZodFromSwagger,
  normalizeSwaggerParameters,
  FormInputObjectType,
} from 'utils/swagger-parser'
import { buildFetch } from 'utils/request-params'
import { renderInputType, formatResponse } from './taskform-helpers'
import {
  StyledControlGroup,
  SubmitButton,
  ResetButton,
  FormSubmitResponse,
} from './task-form-styles'

interface TaskFormProps {
  method: string
  apiPath: string
  operationObject: SwaggerOperationObjectType
}
interface ControllerRenderParams {
  value: string | Array<string> | boolean | number | FiniteDurationObject
  onChange: (
    string?: string | number | string[] | boolean | FiniteDurationObject
  ) => void
}

interface FetchResponseState {
  type: string
  response: Error | unknown
}

export const TaskForm = ({
  operationObject: { parameters },
  method,
  apiPath,
}: TaskFormProps) => {
  const { schema, formData, defaultValues } = useMemo(() => {
    if (!parameters) {
      return { schema: z.any(), formData: [] }
    }
    const normalizedParams = normalizeSwaggerParameters(parameters)
    const schemaFromSwag = getZodFromSwagger(normalizedParams)
    const formDataFromSwag = getInputsFromSwagger(normalizedParams)
    const formDataFromSwagObj = formDataFromSwag.reduce(
      (obj, item: FormInputObjectType) => ({
        ...obj,
        [item.inputId]: item.defaultValue,
      }),
      {}
    )

    return {
      schema: schemaFromSwag,
      formData: formDataFromSwag,
      defaultValues: formDataFromSwagObj,
    }
  }, [parameters])
  type Schema = z.infer<typeof schema>

  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitSuccessful, isSubmitting, errors },
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues,
  })

  const [fetchResponse, setFetchResponse] = useState<FetchResponseState>()
  const onSubmit: SubmitHandler<Schema> = (data) => {
    const fetchRequest = buildFetch(method, apiPath, data, formData)

    fetchRequest()
      .then((res) => {
        const formattedRespose = formatResponse(res)
        setFetchResponse(formattedRespose)
      })
      .catch((err) => {
        const formattedRespose = formatResponse(err)
        setFetchResponse(formattedRespose)
      })
  }

  const onReset = () => {
    reset({ ...defaultValues })
    setFetchResponse(undefined)
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {(formData as FormInputObjectType[]).map(
          ({
            inputDescription,
            inputTitle,
            inputId,
            inputType,
            defaultValue,
          }: FormInputObjectType) => (
            <StyledControlGroup
              labelFor={inputId}
              label={inputTitle}
              tooltip={inputDescription}
              error={inputId in errors}
              help={inputId in errors && errors[inputId].message}
              key={inputId}
            >
              <Controller
                defaultValue={defaultValue}
                key={inputId}
                name={inputId}
                control={control}
                render={({ value, onChange }: ControllerRenderParams) =>
                  renderInputType({
                    inputTitle,
                    inputType,
                    inputId,
                    value,
                    onChange,
                    errors,
                  })
                }
              />
            </StyledControlGroup>
          )
        )}
        <SubmitButton
          type='submit'
          appearance='primary'
          disabled={isSubmitSuccessful || isSubmitting}
          label='Submit'
        />
        <ResetButton
          appearance='destructive'
          label='Reset'
          disabled={!isSubmitSuccessful}
          onClick={onReset}
        />
      </form>

      <FormSubmitResponse>
        {isSubmitting && <WaitSpinner title='loading' />}
        {isSubmitSuccessful && fetchResponse && (
          <Message appearance='fill' type={fetchResponse.type}>
            <Message.Title>{fetchResponse.type.toUpperCase()}</Message.Title>
            <pre>{JSON.stringify(fetchResponse.response, null, 2)}</pre>
          </Message>
        )}
      </FormSubmitResponse>
    </>
  )
}
