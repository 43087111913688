import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: calc(100vh / 6);
  align-items: center;
`

const Content = styled.span`
  color: gray;
  line-height: 1.5;
`

const Heading = styled.span`
  font-size: 24px;
  font-weight: bold;
`

const Image = styled.img`
  margin-bottom: 2px;
`

export const ClientErrorStyles = { Container, Content, Heading, Image }
