import styled from 'styled-components'
import { pick, variables } from '@splunk/themes'

export const AppContainer = styled.div`
  text-align: center;
  display: grid;
  grid-template-columns: 20rem auto;
`

export const AppContent = styled.div`
  background-color: ${pick({
    prisma: variables.backgroundColorPage,
  })};
  min-height: 100vh;
  height: 100%;
  display: grid;
  grid-template-rows: 5rem auto;
`

export const AppMain = styled.main`
  margin: 0.5rem 2rem;
  padding: 0.5rem 2rem;
`

export const AppHome = styled.div`
  margin-top: 10% !important;
`

export const StyledErrorBoundary = styled.div`
  margin: 15%;
  padding: 5%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-items: center;
  justify-content: center;
`

export const AppLogo = styled.img`
  pointer-events: none;
  max-width: 40rem;
  width: 50%;
  height: auto;
`
