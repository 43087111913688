import styled from 'styled-components'
import { pick, variables } from '@splunk/themes'
import Button from '@splunk/react-ui/Button'
import ControlGroup from '@splunk/react-ui/ControlGroup'
import Text from '@splunk/react-ui/Text'
import Number from '@splunk/react-ui/Number'
import Switch from '@splunk/react-ui/Switch'
import Multiselect from '@splunk/react-ui/Multiselect'

export const StyledMultiselect = styled(Multiselect)`
  background-color: ${pick({
    prisma: variables.white,
  })};
`

export const StyledSwitch = styled(Switch)`
  button {
    background-color: ${pick({
      prisma: variables.white,
    })};
  }
`

export const StyledText = styled(Text)`
  background-color: ${pick({
    prisma: variables.white,
  })};
  font-size: ${pick({
    prisma: variables.fontSizeLarge,
  })};
`

export const StyledNumber = styled(Number)`
  background-color: ${pick({
    prisma: variables.white,
  })};
  font-size: ${pick({
    prisma: variables.fontSizeLarge,
  })};
`

export const StyledControlGroup = styled(ControlGroup)`
  font-size: ${pick({
    prisma: variables.fontSizeLarge,
  })};
`

export const ResetButton = styled(Button)`
  font-size: ${pick({
    prisma: variables.fontSizeLarge,
  })};
`

export const SubmitButton = styled(Button)`
  font-size: ${pick({
    prisma: variables.fontSizeLarge,
  })};
`

export const FormSubmitResponse = styled.div`
  margin: 2rem 0;
`
