import React from 'react'

import surpisedCat from './surprised-cat.svg'
import { ClientErrorStyles } from './client-error-styles'

export const ClientError = () => (
  <ClientErrorStyles.Container>
    <ClientErrorStyles.Image src={surpisedCat} alt='Surprised Cat' />
    <ClientErrorStyles.Heading>Page Not Found (404)</ClientErrorStyles.Heading>
    <ClientErrorStyles.Content>
      It looks like this page does not exist.
    </ClientErrorStyles.Content>
  </ClientErrorStyles.Container>
)
