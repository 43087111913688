import React from 'react'
import Menu from '@splunk/react-ui/Menu'

import {
  AppSidebar,
  StyledLinkLogo,
  CategoryHeader,
  StyledNavLink,
} from './sidebar-styles'

interface Route {
  tag: string
  summary: string
  apiPath: string
  method: string
  clientRoute: string
}
interface MappingWithIndexSignature {
  [propName: string]: Route[]
}

export const Sidebar = ({ routes }: { routes: Route[] }) => {
  /* eslint-disable no-param-reassign */
  const groupedRoutes = routes.reduce<MappingWithIndexSignature>(
    (acc: MappingWithIndexSignature, curr: Route) => {
      acc[curr.tag] = acc[curr.tag] || []
      acc[curr.tag].push(curr)
      return acc
    },
    {}
  )
  /* eslint-enable no-param-reassign */

  const sortedRoutes = Object.keys(groupedRoutes)
    .sort()
    .reduce<MappingWithIndexSignature>((obj, key) => {
      const keyRoutes = groupedRoutes[key]
      // eslint-disable-next-line no-param-reassign
      obj[key] = keyRoutes.sort((a, b) => a.summary.localeCompare(b.summary))
      return obj
    }, {})
  return (
    <AppSidebar>
      <div>
        <StyledLinkLogo to='/'>
          <h1>Splunk On-Call Ops Console</h1>
        </StyledLinkLogo>
      </div>
      {Object.keys(sortedRoutes).map((tag) => (
        <div key={tag}>
          <CategoryHeader key={tag}>{tag}</CategoryHeader>
          <Menu>
            {sortedRoutes[tag].map((route: Route) => (
              <StyledNavLink
                to={route.clientRoute}
                key={route.clientRoute}
                data-test={route.clientRoute}
              >
                <Menu.Item>{route.summary}</Menu.Item>
              </StyledNavLink>
            ))}
          </Menu>
        </div>
      ))}
    </AppSidebar>
  )
}
