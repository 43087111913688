/* eslint-disable max-classes-per-file */

interface ErrorSource {
  parameter?: string
  pointer?: string
}

export interface ErrorInfo {
  details?: string
  title?: string
  status?: number
  source?: ErrorSource
}

export interface CustomErrorType extends Error {
  name: string
  message: string
  details?: string
  title?: string
  status?: number
  source?: ErrorSource
}

/**
 * Matches error object coming from service
 * See https://jsonapi.org/examples/#error-objects for explanation of params
 */
export class CustomError extends Error {
  [x: string]: ErrorSource | number | string | undefined

  constructor(message: string, info?: ErrorInfo) {
    super(message)

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError)
    }

    this.name = this.constructor.name
    this.details = info?.details
    this.title = info?.title
    this.status = info?.status
    this.source = info?.source
  }
}

export class AuthTokenError extends CustomError {
  constructor(message?: string, info?: ErrorInfo) {
    super(message ?? 'Failed to obtain an access token; redirecting to login.')
    this.title = info?.title ?? 'Unauthorized'
    this.status = info?.status ?? 401
    this.details = info?.details
    this.source = info?.source
  }
}

export class FetchError extends CustomError {}

export class FormSubmitError extends CustomError {}

export class ResponseError extends CustomError {}

/* eslint-enable max-classes-per-file */
