import React, { useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'

import {
  AppHeader,
  StyledDropdownOption,
  StyledDropdown,
  DropdownContent,
  DropdownButton,
} from './header-styles'

export const Header = () => {
  const { authState, oktaAuth } = useOktaAuth()
  const [userName, setUserName] = useState<string>()
  const links = [{ name: 'Logout', slug: '/logout' }]
  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth.getUser().then((userInfo) => setUserName(userInfo.name))
    } else {
      setUserName('Anonymous')
    }
  }, [authState, oktaAuth])

  const toggle = <DropdownButton appearance='toggle' label={userName} isMenu />

  return (
    <AppHeader>
      <StyledDropdown toggle={toggle}>
        <DropdownContent>
          {links.map((link) => (
            <StyledDropdownOption key={link.name} style={{ width: 120 }}>
              <a key={link.name} href={link.slug}>
                {link.name}
              </a>
            </StyledDropdownOption>
          ))}
        </DropdownContent>
      </StyledDropdown>
    </AppHeader>
  )
}
