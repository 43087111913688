/* eslint-disable import/newline-after-import */
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import SplunkThemeProvider from '@splunk/themes/SplunkThemeProvider'
import 'whatwg-fetch'
import { App } from './App'
import { reportWebVitals } from './reportWebVitals'
import { GlobalStyle } from './index-styles'

// prettier keeps adding semicolon here and it's conflicting with eslint newline rule
;(async () => {
  if (process.env.NODE_ENV === 'development') {
    const { worker } = await import('./mocks/browser')
    worker.start()
  }

  Sentry.init({
    dsn:
      'https://041a4d20fb6748ee8f89378e50ae81d9@o378897.ingest.sentry.io/5641666',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT,
  })

  ReactDOM.render(
    <React.StrictMode>
      <GlobalStyle />
      <SplunkThemeProvider
        family='prisma'
        density='comfortable'
        colorScheme='light'
      >
        <App />
      </SplunkThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
  )

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
})()
/* eslint-enable import/newline-after-import */
