import { NavLink } from 'react-router-dom'

import styled from 'styled-components'
import Menu from '@splunk/react-ui/Menu'

export const AppSidebar = styled(Menu)`
  text-align: left;
  margin: 20px;
  padding: 20px;
  margin-top: 5px;
  padding-top: 5px;
`

export const StyledLinkLogo = styled(NavLink)`
  text-decoration: none;
  line-height: 1.6;
  text-align: center;
  color: black;
  &:hover {
    text-decoration: none !important;
  }
`

export const CategoryHeader = styled(Menu.Heading)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 18px;
`

export const StyledNavLink = styled(NavLink).attrs((props) => ({
  activeClassName: props.activeClassName || 'active',
}))`
  margin-top: 0;
  color: black;
  text-decoration: none;
  & > button {
    &:focus {
      box-shadow: none !important;
    }
  }
  &.${(props) => props.activeClassName} {
    & > button {
      font-weight: bold;
      box-shadow: none !important;
    }
  }
`
