import { FormInputObjectType } from 'utils/swagger-parser'
import { deleteFetch, getFetch, postFetch, putFetch } from 'utils/fetch'
import { FormSubmitError } from 'utils/custom-errors'

export interface FormDataSchema<T> {
  [key: string]: T
}

/**
 * @description Filters submitted form data by paramLocation
 * @function getParamsByLocation
 * @param paramLocation Equivalent to Swagger spec's 'in'
 * @param submitData User submitted data
 * @param formParams Describes params that make up the form
 */
export const getParamsByLocation = <T extends FormDataSchema<unknown>>(
  paramLocation: string,
  submitData: T,
  formParams: FormInputObjectType[]
) =>
  formParams
    .filter((param) => param.paramLocation === paramLocation)
    .reduce((acc: Record<string, unknown>, { inputId }) => {
      // eslint-disable-next-line no-param-reassign
      acc[inputId] = submitData[inputId]
      return acc
    }, {})

/**
 * @description Returns API path with user data replacing params in the path template
 * @function getPathParams
 * @param path Swagger API path
 * @param submitData User submitted data
 * @param formParams Describes params that make up the form
 */
export const getPathParams = <T extends FormDataSchema<unknown>>(
  path: string,
  submitData: T,
  formParams: FormInputObjectType[]
) => {
  const pathParams = getParamsByLocation('path', submitData, formParams)
  return Object.entries(pathParams).reduce((acc: string, [k, v]) => {
    // eslint-disable-next-line no-param-reassign
    acc = acc.replace(`{${k}}`, `${v}`)
    return acc
  }, path)
}

/**
 * @description Returns URLSearchParams string of user submitted data
 * @function getQueryParams
 * @param submitData User submitted data
 * @param formParams Describes params that make up the form
 */
export const getQueryParams = <T extends FormDataSchema<unknown>>(
  submitData: T,
  formParams: FormInputObjectType[]
) => {
  const queryParams = getParamsByLocation('query', submitData, formParams)
  const queryParamsStringValues = Object.fromEntries(
    Object.entries(queryParams).map(([k, v]) => [k, `${v}`])
  )
  return new URLSearchParams(queryParamsStringValues).toString()
}

/**
 * @description Returns object of user form data to be submitted in request body
 * @function getBodyParams
 * @param submitData User submitted data
 * @param formParams Describes params that make up the form
 */
export const getBodyParams = <T extends FormDataSchema<unknown>>(
  submitData: T,
  formParams: FormInputObjectType[]
) => getParamsByLocation('body', submitData, formParams)

/**
 * Constructs request method with params in correct location based on swagger spec
 * @param method Request Method
 * @param apiPath API path request will be submitted to
 * @param submitData User data from form
 * @param formParams Form params spec
 */
export const buildFetch = <T extends FormDataSchema<unknown>>(
  method: string,
  apiPath: string,
  submitData: T,
  formParams: FormInputObjectType[]
) => {
  if (method === 'post') {
    const bodyParams = getBodyParams(submitData, formParams)
    const pathWithParams = getPathParams(apiPath, submitData, formParams)
    return () => postFetch(pathWithParams, bodyParams)
  }
  if (method === 'get') {
    const queryParams = getQueryParams(submitData, formParams)
    const pathWithParams = getPathParams(apiPath, submitData, formParams)
    return () => getFetch(`${pathWithParams}?${queryParams}`)
  }
  if (method === 'put') {
    const bodyParams = getBodyParams(submitData, formParams)
    const pathWithParams = getPathParams(apiPath, submitData, formParams)
    return () => putFetch(pathWithParams, bodyParams)
  }
  if (method === 'delete') {
    const queryParams = getQueryParams(submitData, formParams)
    const pathWithParams = getPathParams(apiPath, submitData, formParams)
    return () => deleteFetch(`${pathWithParams}?${queryParams}`)
  }
  throw new FormSubmitError(`Unhandled request method: ${method}.`)
}
