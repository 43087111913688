import React, { useEffect, useState } from 'react'
import SwaggerClient from 'swagger-client'
import WaitSpinner from '@splunk/react-ui/WaitSpinner'
import useSWR from 'swr'
import { swrOptions } from 'config/swrConfig'
import { getFetch } from 'utils/fetch'
import { TaskForm } from 'components/TaskForm'
import { AppTaskPage } from './task-page-styles'

interface TaskPageProps {
  summary: string
  apiPath: string
  tag: string
  method: string
}

interface SwaggerClientResolveType {
  errors: []
  spec: SwaggerObjectType
}

/* eslint no-shadow: 0 */
enum StatusTypes {
  Loading = 'LOADING',
  Success = 'SUCCESS',
  Error = 'ERROR',
}

export const TaskPage = ({
  summary: operationSummary,
  apiPath,
  tag,
  method,
}: TaskPageProps) => {
  const [apiTagSpec, setApiTagSpec] = useState<SwaggerObjectType>()
  const [apiSpec, setApiSpec] = useState<SwaggerOperationObjectType>()
  const [fetchStatus, setFetchStatus] = useState<StatusTypes>(
    StatusTypes.Loading
  )
  const { data: rawSwaggerSpec, error: fetchError } = useSWR(
    () => (tag !== undefined ? `/service/info/specs/${tag}` : null),
    getFetch,
    swrOptions(`/service/info/specs/${tag}`)
  )

  const resolveSwaggerSpec = async (specFromFetch: SwaggerObjectType) => {
    const {
      errors,
      spec,
    }: SwaggerClientResolveType = await SwaggerClient.resolve({
      spec: specFromFetch,
    })
    if (errors.length) {
      setFetchStatus(StatusTypes.Error)
    } else {
      setFetchStatus(StatusTypes.Success)
      setApiTagSpec(spec)
    }
  }

  useEffect(() => {
    if (rawSwaggerSpec !== undefined) {
      resolveSwaggerSpec(rawSwaggerSpec)
    }
    if (fetchError) {
      setFetchStatus(StatusTypes.Error)
    }
  }, [rawSwaggerSpec, fetchError])

  useEffect(() => {
    try {
      if (
        (apiPath !== undefined && apiPath.length === 0) ||
        (method !== undefined && method.length === 0)
      ) {
        throw new Error()
      }
      setApiSpec(apiTagSpec?.paths[apiPath][method])
    } catch {
      setFetchStatus(StatusTypes.Error)
    }
  }, [apiTagSpec, apiPath, method])

  return (
    <AppTaskPage>
      <h2>{operationSummary}</h2>
      {fetchStatus === StatusTypes.Loading && <WaitSpinner />}
      {fetchStatus === StatusTypes.Success && apiSpec && (
        <div>
          <h3>{apiSpec.description}</h3>
          <TaskForm
            operationObject={apiSpec}
            apiPath={apiPath}
            method={method}
          />
        </div>
      )}
      {fetchStatus === StatusTypes.Error && (
        <p>{`No API spec defined for the path ${apiPath}`}</p>
      )}
    </AppTaskPage>
  )
}
